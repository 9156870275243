<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visable"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
    </v-alert>


    <view-intro class="text-start" :heading="document + ( productModel.name ? ' / ' + productModel.name : '') "/>
    <div class="mb-4">
      <v-btn
        color="primary"
        elevation="2"
        outlined
        @click="$router.go(-1)"
      >&lArr; Terug
      </v-btn>
      <v-btn
        class="ml-4"
        elevation="2"
        icon
        color="red"
        outlined
        @click="removeProduct"
      >
        <i class="fa-regular fa-trash-alt"></i>
      </v-btn>
    </div>
    <div v-if="loading" class="d-flex justify-content-center" style="margin-top: 30vh;">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <FormulateInput
        type="image"
        name="headshot"
        label="Selecteer een afbeelding om te uploaden"
        help="Select a png, jpg, or gif to upload."
        validation="mime:image/jpeg,image/png,image/heic"
        @file-upload-complete="saveImage"
        @file-removed="removeImage"
        class="mt-6 mb-3"
        :value="[initialImage]"
      ></FormulateInput>
      <vue-form-generator ref="form" :schema="productModel.validForDays ? subscriptionSchema : schema" :model="productModel" :options="formOptions"></vue-form-generator>
      <div class="mt-4">
        <v-btn
          color="primary"
          elevation="2"
          outlined
          @click="$router.go(-1)"
        >&lArr; Terug
        </v-btn>
        <v-btn
          class="ml-3"
          color="primary"
          elevation="2"
          @click="handleSubmit"
          :loading="saveLoading"
        >Opslaan
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
  import VueFormGenerator from 'vue-form-generator'

  export default {
    name: 'ProductDetail',
    data () {
      const {
        document,
        productId,
      } = this.$route.params
      if (!document || !productId) return this.$router.push('/notfound')
      this.$auth.getProduct(document, productId).then(async product => {
        if (product === 'Product not found') {
          this.$swal({
            icon: 'error',
            title: 'Product is niet gevonden/verwijderd',
          }).then(()=> {
            history.back()
          })
        }
        this.productModel = this.$filter.filterOrder(product)
        if (this.productModel.imageAvailable) {
          const imageUrl = await this.$auth.getImgUrl(this.productModel.id)
          this.initialImage = {
            url: imageUrl + '.jpeg',
            name: this.productModel.name + ' foto',
          }
        }
      }).catch(err => {
        this.alert.visable = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      }).finally(() => {
        this.loading = false
      })

      return {
        initialImage: {},
        image: null,
        saveLoading: false,
        productModel: {
          id: '',
          name: '',
          priceNumber: 0,
          priceFixer: '',
          section: '',
        },
        schema: {
          fields: [
            {
              type: 'input',
              inputType: 'text',
              label: 'Naam',
              model: 'name',
              min: 3,
              required: true,
              hint: 'Minimaal 3 karakters',
              validator: VueFormGenerator.validators.string,
            },
            {
              type: 'input',
              inputType: 'number',
              label: 'Prijs',
              model: 'priceNumber',
              required: true,
              validator: VueFormGenerator.validators.number,
            },
            {
              type: 'input',
              inputType: 'text',
              label: 'Prijs fixer',
              model: 'priceFixer',
              validator: VueFormGenerator.validators.string,
            },
            {
              type: 'input',
              inputType: 'text',
              label: 'vak',
              model: 'section',
              validator: VueFormGenerator.validators.string,
            },
          ],
        },
      subscriptionSchema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Naam',
            model: 'name',
            min: 3,
            required: true,
            hint: 'Minimaal 3 karakters',
            validator: VueFormGenerator.validators.string,
          },
          {
            type: 'input',
            inputType: 'number',
            label: 'Prijs',
            model: 'priceNumber',
            required: true,
            validator: VueFormGenerator.validators.number,
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Prijs fixer',
            model: 'priceFixer',
            validator: VueFormGenerator.validators.string,
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'vak',
            model: 'section',
            validator: VueFormGenerator.validators.string,
          },
          {
            type: 'input',
            inputType: 'number',
            label: 'Korting percentage',
            model: 'presentage',
            validator: VueFormGenerator.validators.number,
          },
          {
            type: 'input',
            inputType: 'number',
            label: 'Dagen geldig',
            model: 'validForDays',
            validator: VueFormGenerator.validators.number,
          },
        ],
      },
      formOptions: {
          validateAfterLoad: false,
          validateAfterChanged: true,
          validateAsync: true,
        },
        document,
        loading: true,
        alert: {
          visable: false,
          description: '',
        },
      }
    },
    methods: {
      saveImage (image) {
        this.image = image.previewData
      },
      removeImage () {
        this.image = 'removed'
      },
      async handleSubmit () {
        this.productModel.section = this.productModel.section.length ? this.productModel.section.trim() : '';
        this.productModel.name = this.productModel.name.length ? this.productModel.name.trim() : '';
        this.saveLoading = true
        try {
          const validationErrors = await this.$refs.form.validate()
          if (validationErrors.length !== 0) return
          if (this.image === 'removed' || !this.image) {
            const status = await this.$auth.setProduct(this.document, this.productModel, this.image === 'removed' ? undefined : 'dontRemove')
            this.saveLoading = false
            if (status === 'Product not found') new Error(status)
            this.$swal({
              icon: 'success',
              title: 'Succesvol opgeslagen',
            })
            return
          }
          const base64Response = await fetch(this.image)
          const blob = await base64Response.blob()

          await this.$filter.compressProductImage(blob, this.setProduct)

        } catch (err) {
          console.log(err)
          this.$swal({
            icon: 'error',
            title: 'fout...',
            text: 'Er is iets fout gegaan! Neem contact met beheerder',
          })
        }
      },
      async setProduct (compressedBlob) {
        const status = await this.$auth.setProduct(this.document, this.productModel, compressedBlob)
        this.saveLoading = false
        if (status === 'Product not found') new Error(status)
        this.$swal({
          icon: 'success',
          title: 'Succesvol opgeslagen',
        })
      },
      removeProduct () {
        this.$swal.fire({
          title: 'weet u het zeker',
          text: `Wilt u dit product verwijderen?`,
          icon: 'warning',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Verwijder',
          cancelButtonText: 'Annuleren',
        }).then(result => {
          if (!result.isConfirmed) return
          this.$auth.deleteProduct(this.document, this.productModel.id).then(() => {
            this.$swal({
              title: `Product is successvol verwijderd`,
              icon: 'success',
            })
          }).catch(() => {
              this.$swal({
                title: 'Er is iets mis gegaan met het verwijderen',
                icon: 'error',
                text: 'Neem graag contact op met beheerder',
              })
            },
          ).finally(() => {
            this.$router.push(`/products/${this.document}`)
          })
        })
      },
    },
  }
</script>
